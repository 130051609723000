<template>
  <FocusTrap>
    <div class="card" id="cashier_card" >
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Cashier information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">User Code:</label>
          <div class="col-md-3">
            <input type="number" id="txtCode" class="form-control" placeholder="User Login Code" v-if="cashier" autocomplete="off" v-model="cashier.usercode">
          </div>

          <label class="col-md-1 col-form-label">Password:</label>
          <div class="col-md-2">
            <input type="password" class="form-control" placeholder="Password" v-if="cashier" autocomplete="off" v-model="cashier.password" :disabled="cashier.id != 0"  >
          </div>

          <label class="col-md-2 col-form-label">Retype Password:</label>
          <div class="col-md-2">
            <input type="password" class="form-control" placeholder="Password" v-if="cashier" autocomplete="off" v-model="cashier.c_password" :disabled="cashier.id != 0">
          </div>

        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="Cashier Name" autocomplete="off" v-if="cashier" v-model="cashier.name">
          </div>

          <label class="col-md-1 col-form-label">Ledger:</label>
          <div class="col-md-3">
            <select id="cmbLedger" class="form-control" v-if="cashier" v-model="cashier.ldgr_id">
              <option v-for="ledger in ledgers" v-bind:value="ledger.id"> {{ledger.name}}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Street:</label>
          <div class="col-md-10">
            <textarea rows="3" class="form-control" placeholder="Enter address here" v-if="cashier" autocomplete="off" v-model="cashier.street"></textarea>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">City:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="City" maxlength="50" autocomplete="off" v-if="cashier" v-model="cashier.city">
          </div>

          <label class="col-md-1 col-form-label">PIN:</label>
          <div class="col-md-2">
            <input type="text" class="form-control" placeholder="Pincode" maxlength="7" v-if="cashier" v-model="cashier.pin" autocomplete="off">
          </div>

          <label class="col-md-1 col-form-label">Mobile:</label>
          <div class="col-md-2">
            <input type="text" class="form-control" placeholder="Mobile No" maxlength="10" v-if="cashier" v-model="cashier.mobile" autocomplete="off">
          </div>
        </div>

<!--        <div class="form-group row">-->
<!--          <label class="col-md-2 col-form-label">State:</label>-->
<!--          <div class="col-md-7">-->
<!--            <select data-placeholder="Select your state" class="form-control" v-if="cashier"  >-->
<!--              <option value="37">Andhra Pradesh</option>-->
<!--              <option value="35">Andaman and Nicobar Islands</option>-->
<!--              <option value="12">Arunachal Pradesh</option>-->
<!--              <option value="18">Assam</option>-->
<!--              <option value="10">Bihar</option>-->
<!--              <option value="04">Chandigarh</option>-->
<!--              <option value="22">Chhattisgarh</option>-->
<!--              <option value="26">Dadar and Nagar Haveli</option>-->
<!--              <option value="25">Daman and Diu</option>-->
<!--              <option value="07">Delhi</option>-->
<!--              <option value="30">Goa</option>-->
<!--              <option value="24">Gujarat</option>-->
<!--              <option value="06">Haryana</option>-->
<!--              <option value="02">Himachal Pradesh</option>-->
<!--              <option value="01">Jammu and Kashmir</option>-->
<!--              <option value="20">Jharkhand</option>-->
<!--              <option value="29">Karnataka</option>-->
<!--              <option value="32">Kerala</option>-->
<!--              <option value="31">Lakshadweep</option>-->
<!--              <option value="23">Madhya Pradesh</option>-->
<!--              <option value="27">Maharashtra</option>-->
<!--              <option value="14">Manipur</option>-->
<!--              <option value="17">Meghalaya</option>-->
<!--              <option value="15">Mizoram</option>-->
<!--              <option value="13">Nagaland</option>-->
<!--              <option value="21">Odisha</option>-->
<!--              <option value="34">Pondicherry</option>-->
<!--              <option value="03">Punjab</option>-->
<!--              <option value="08">Rajasthan</option>-->
<!--              <option value="11">Sikkim</option>-->
<!--              <option value="33" selected="">Tamil Nadu</option>-->
<!--              <option value="36">Telangana</option>-->
<!--              <option value="16">Tripura</option>-->
<!--              <option value="09">Uttar Pradesh</option>-->
<!--              <option value="05">Uttarakhand</option>-->
<!--              <option value="19">West Bangal</option>-->
<!--            </select>-->
<!--          </div>-->

<!--          <label class="col-md-1 col-form-label">Mobile:</label>-->
<!--          <div class="col-md-2">-->
<!--            <input type="text" class="form-control" placeholder="Mobile No" maxlength="10" v-if="cashier" v-model="cashier.mobile" autocomplete="off">-->
<!--          </div>-->
<!--        </div>-->

        <div class="text-right">
          <SaveButton  @handle_save_action="saveDocument"></SaveButton>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'
export default {
  name: 'CashierForm',
  components: { SaveButton },
  store,
  data () {
    return {
      ledgers : [],
      cashier: JSON.parse('{"id":0,"usercode":"","password":"","c_password":"","name":"","street":"","city":"","pin":"","mobile":"","brn_id":1,"ldgr_id":1,"user_id":0,"opening_balance":0,"current_balance":0,"user":{"id":0,"name":"","email":""},"ledger":{"id":1,"name":"","alias":"","open_bal":0,"group":{"id":1,"name":"","alias":""}}}')
    }
  },
  component: {

  },
  props: {
    mycashier: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"usercode":"","password":"","c_password":"","name":"","street":"","city":"","pin":"","mobile":"","brn_id":1,"ldgr_id":1,"user_id":0,"opening_balance":0,"current_balance":0,"user":{"id":0,"name":"","email":""},"ledger":{"id":1,"name":"","alias":"","open_bal":0,"group":{"id":1,"name":"","alias":""}}}')
    }
  },
  beforeMount () {
    this.cashier = this.mycashier; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.cashier = JSON.parse('{"id":0,"usercode":"","password":"","c_password":"","name":"","street":"","city":"","pin":"","mobile":"","brn_id":1,"ldgr_id":1,"user_id":0,"opening_balance":0,"current_balance":0,"user":{"id":0,"name":"","email":""},"ledger":{"id":1,"name":"","alias":"","open_bal":0,"group":{"id":1,"name":"","alias":""}}}')
  },
  mounted () {
    const self = this;
    // $('.form-control-select2').select2();
    this.loadLedgers();
    $('#txtCode').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('cashier_window_closed');
    },
    loadLedgers(){
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#cashier_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/cashier_ledgers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if(Array.isArray(resp.data)) {
            self.$data.ledgers = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error', onClose: () => { $('#txtCode').focus()}, timer:3000  });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtCode').focus()}, timer:3000  });
      }).finally(function () {
        $('#cashier_card').unblock();
      });

    },
    saveDocument () {
      const self = this;

      try {
        if (self.$data.cashier.name.toString().length < 3) {
          alert('Invalid Name');
          return;
        } else if (self.$data.cashier.ldgr_id < 1) {
          alert('Invalid Cashier Ledger');
          return;
        } else if (self.$data.cashier.usercode.toString().length < 3) {
          alert('Invalid User Code');
          return;
        }

        const requestOptions = {
          method: (self.$data.cashier.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.cashier)
        }

        console.log(JSON.stringify(self.$data.cashier));

        $('#cashier_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/cashier`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({
              title: 'Success!', text: resp.msg, type: 'success', onClose: () => {
                $('#txtCode').focus()
              }, timer: 1500
            });
            self.cashier = JSON.parse('{"id":0,"usercode":"","password":"","c_password":"","name":"","street":"","city":"","pin":"","mobile":"","brn_id":1,"ldgr_id":1,"user_id":0,"opening_balance":0,"current_balance":0,"user":{"id":0,"name":"","email":""},"ledger":{"id":1,"name":"","alias":"","open_bal":0,"group":{"id":1,"name":"","alias":""}}}');
            self.$emit('cashier_saved', resp.data);
          } else {
            swal({
              title: 'Oops!', text: resp.msg, type: 'error', onClose: () => {
                $('#txtCode').focus()
              }, timer: 3000
            });
          }
        }).catch(function (err) {
          swal({
            title: 'Oh noes!', text: err.toString(), type: 'error', onClose: () => {
              $('#txtCode').focus()
            }, timer: 3000
          });
        }).finally(function () {
          $('#cashier_card').unblock();
        });
      }catch (e) {
        alert(e);
      }

    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
