<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Cashier's List</h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="cashier_table"
                 class="table table-bordered table-condensed table-columned"
                 data-search="false"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-pagination="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">ID</th>
              <th data-formatter="runningFormatter" data-width="75">S.No</th>
              <th data-field="name" data-sortable="true">Name</th>
              <th data-field="city" data-width="200" data-sortable="true">City</th>
              <th data-field="mobile" data-width="100" data-sortable="true">Mobile</th>
              <th data-field="ledger.name" data-width="200" data-sortable="true">Ledger</th>
              <th data-field="usercode" data-width="100" data-align="right" data-sortable="true">User Code</th>
              <th data-field="currentbalance" data-width="100" data-formatter="indianFormat" data-align="right" data-sortable="true">Current Balance</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Delete</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
            <i class="fab-icon-open icon-plus3"></i>
            <i class="fab-icon-close icon-plus3"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <vmodal name="cashier_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="999" :height="425" @closed="closed" :draggable="true" >
        <CashierForm  v-bind:mycashier="cashier" v-on:cashier_saved="loadData" v-on:cashier_window_closed="closeModal" />
      </vmodal>

    </div>
  </FocusTrap>
</template>

<script>


import CashierForm from './CashierForm'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'CashierView',
  components: {
    CashierForm
  },
  store,
  data () {
    return {
      mytable: {},
      cashier: {"id":0,"usercode":"","password":"","c_password":"","name":"","street":"","city":"","pin":"","mobile":"","brn_id":1,"ldgr_id":1,"user_id":0,"opening_balance":0,"current_balance":0,"user":{"id":0,"name":"","email":""},"ledger":{"id":1,"name":"","alias":"","open_bal":0,"group":{"id":1,"name":"","alias":""}}},
    }
  },
  created () {
  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#cashier_table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#cashier_table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        var id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        }else if ($(e.target).text() === 'Delete') {
          self.removeDocument(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {

      var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

      $('#cashier_table>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide();

    });

    self.loadData();

  },
  methods: {
    closeModal () {
      this.$modal.hide('cashier_window')
    },
    closed(){
      this.cashier = {"id":0,"usercode":"","password":"","c_password":"","name":"","street":"","city":"","pin":"","mobile":"","brn_id":1,"ldgr_id":1,"user_id":0,"opening_balance":0,"current_balance":0,"user":{"id":0,"name":"","email":""},"ledger":{"id":1,"name":"","alias":"","open_bal":0,"group":{"id":1,"name":"","alias":""}}};
    },
    showModal () {
      // this.cashier = JSON.parse('{"id":0,"usercode":"","password":"","c_password":"","name":"","street":"","city":"","pin":"","mobile":"","brn_id":1,"ldgr_id":1,"user_id":0,"opening_balance":0,"current_balance":0,"user":{"id":0,"name":"","email":""},"ledger":{"id":1,"name":"","alias":"","open_bal":0,"group":{"id":1,"name":"","alias":""}}}');
      this.$modal.show('cashier_window')
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.cashier =  {"id":0,"usercode":"","password":"","c_password":"","name":"","street":"","city":"","pin":"","mobile":"","brn_id":1,"ldgr_id":1,"user_id":0,"opening_balance":0,"current_balance":0,"user":{"id":0,"name":"","email":""},"ledger":{"id":1,"name":"","alias":"","open_bal":0,"group":{"id":1,"name":"","alias":""}}};
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/cashier/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.cashier = resp.data;
          if(resp.data.opening_balance < 1){
            resp.data.opening_balance = Math.abs(resp.data.opening_balance);
            self.$data.is_crdr = -1;
          }else{
            self.$data.is_crdr = 1;
          }

          self.$modal.show('cashier_window');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
      });
    },
    removeDocument (id) {
      const self = this;

      self.$data.cashier.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.cashier)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete this!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/cashier`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('cashier_window')
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            );
            // swal ( { title: "Success!" ,  text: data.message, type:  "success"} );
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });

    },
    loadData() {
      const self = this;

      this.$modal.hide('cashier_window')

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/cashiers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.mytable.bootstrapTable('load', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    },
  }

}
</script>

<style scoped>

</style>
